import styled from 'styled-components';

import { RouterLink } from '~/src/ui-kit';

export const FormStyled = styled.form`
  > * + * {
    margin-top: 24px;
  }
`;

export const InputFieldsBox = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 16px;
  }
`;

export const SideOptionsBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LinkStyled = styled(RouterLink)`
  line-height: 24px;
`;

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 8px;
  }
`;

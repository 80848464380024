import React from 'react';
import { useForm } from 'react-hook-form';

import {
  Button,
  H1,
  RadioButton,
} from '~/src/ui-kit';
import { ContentBox } from '~/src/components/ContentBox';
import { ErrorCard } from '~/src/components/ErrorCard';
import { InviteOptionsSkeleton } from './components/InviteOptionsSkeleton';
import { StyledForm, StyledDescription, RadioGroupStyled } from './InvitePage.styles';

import type { InvitePageProps, InviteFormValues } from './InvitePage.types';

export function InvitePage({
  isLoading,
  errorMessage,
  must,
  email,
  inviteHandler,
}: InvitePageProps): JSX.Element {
  const inviteOptions = [
    ...(must === 'accept' ? [
      {
        value: 'current',
        label: 'Accept invite',
      },
    ] : []),
    ...(must === 'login' ? [
      {
        value: 'login',
        label: `Sign In with ${email}`,
      },
    ] : []),
    ...(must === 'register' ? [
      {
        value: 'register',
        label: `Register with ${email}`,
      },
    ] : []),
  ];

  const {
    watch,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<InviteFormValues>({
    defaultValues: {
      inviteOption: 'login',
    },
  });

  const {
    ref,
    name,
    onBlur,
    onChange,
  } = register<keyof InviteFormValues>('inviteOption');

  const { inviteOption } = watch();

  return (
    <ContentBox>
      <StyledForm onSubmit={handleSubmit(inviteHandler)}>
        <H1>Invite Option</H1>
        <StyledDescription>
          Please choose one of the following invite options:
        </StyledDescription>
        {isLoading
          ? <InviteOptionsSkeleton />
          : (
            <RadioGroupStyled>
              {inviteOptions.map(({ label, value }) => (
                <RadioButton
                  inputRef={ref}
                  key={value}
                  label={label}
                  name={name}
                  value={value}
                  checked={inviteOption === value}
                  onBlur={onBlur}
                  onChange={onChange}
                />
              ))}
            </RadioGroupStyled>
          )}
        {!!errorMessage && !isSubmitting && <ErrorCard message={errorMessage} />}
        <Button
          width="100%"
          type="submit"
          color="primary"
          isLoading={isLoading || isSubmitting}
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </StyledForm>
    </ContentBox>
  );
}

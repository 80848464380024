import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { TextInputField } from '@pushwoosh/kit-text-input';
import { Tag } from '@pushwoosh/kit-constants';
import { Link } from '@pushwoosh/kit-link';

import {
  FieldBox,
  Checkbox,
  Button,
  H1,
} from '~/src/ui-kit';
import { useEnvironment } from '~/src/contexts/environment';
import { ReCAPTCHA, useRecaptcha } from '~/src/components/Recaptcha';
import { ErrorCard } from '~/src/components/ErrorCard';
import { Backlinks } from '~/src/components/Backlinks';
import { ContentBox } from '~/src/components/ContentBox';
import { ButtonLoader, ButtonLoaderSpinner } from '~/src/components/ButtonLoader';

import {
  FormStyled,
  InputFieldsBox,
  SideOptionsBox,
  LinkStyled,
  ButtonsBox,
} from './LoginPage.styles';
import { LoginPageProps } from './LoginPage.types';

export function LoginPage({
  loginHandler,
  checkForSSOHandler,
  isSSOChecked,
  errorMessage,
  samlUrl,
}: LoginPageProps): JSX.Element {
  const { getLinkByName, productName } = useEnvironment();
  const { isRecaptchaReady, getRecaptchaToken } = useRecaptcha(productName);
  const [isShowPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const showGoogle = searchParams.get('google') != null;
  const isAllowRegister = productName === 'Pushwoosh';

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    getValues,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      password: '',
      username: '',
    },
  });

  async function login(): Promise<void> {
    !isSSOChecked
      ? await checkForSSOHandler({
        token: await getRecaptchaToken(),
        username: getValues('username'),
      })
      : await loginHandler({
        token: await getRecaptchaToken(),
        password: getValues('password'),
        username: getValues('username'),
      });
  }

  return (
    <>
      <ContentBox>
        <FormStyled onSubmit={handleSubmit(login)}>
          <H1>{`Sign in to ${productName}`}</H1>
          <InputFieldsBox>
            <FieldBox
              title="Username or Email"
              isErrored={!!errors.username}
              helpText={errors.username && 'Please enter valid email'}
            >
              <TextInputField
                $isErrored={!!errors.username}
                $withIcon={false}
                autoFocus={!isSSOChecked}
                {...register('username', { required: true })}
              />
            </FieldBox>
            {isSSOChecked && (
              <>
                <FieldBox
                  title="Password"
                  isErrored={!!errors.password}
                  helpText={errors.password && 'Please enter valid password'}
                >
                  <TextInputField
                    type={isShowPassword ? 'text' : 'password'}
                    $isErrored={!!errors.password}
                    $withIcon={false}
                    autoFocus={isSSOChecked}
                    {...register('password', { required: true })}
                  />
                </FieldBox>
                <SideOptionsBox>
                  <Checkbox
                    name="show-password"
                    value
                    isChecked={isShowPassword}
                    onChange={(checkbox): void => setShowPassword(checkbox.isChecked)}
                  >
                    Show password
                  </Checkbox>
                  <LinkStyled
                    as={LinkStyled}
                    title="Forgot password?"
                    to={`${getLinkByName('resetPassword')}?email=${encodeURIComponent(watch('username'))}`}
                  >
                    Forgot password?
                  </LinkStyled>
                </SideOptionsBox>
              </>
            )}
            {!!errorMessage && !isSubmitting && <ErrorCard message={errorMessage} />}
          </InputFieldsBox>
          <ReCAPTCHA />
          <ButtonsBox>
            {
              isRecaptchaReady
                ? (
                  <Button type="submit" isLoading={isSubmitting} color="primary" width="100%">
                    Sign in
                  </Button>
                )
                : (
                  <ButtonLoader>
                    <ButtonLoaderSpinner size="small" />
                    Getting everything ready...
                  </ButtonLoader>
                )
            }
            {
              samlUrl
              && (
                <Button as="a" href={samlUrl} type="button" color="primary" width="100%" view="ghost">
                  Sign In with SSO
                </Button>
              )
            }
            {
              showGoogle
              && (
                <Button as="a" href="/api/auth-with-google" type="button" color="primary" width="100%" view="ghost">
                  Sign In With Google
                </Button>
              )
            }
          </ButtonsBox>
        </FormStyled>
      </ContentBox>
      {
        isAllowRegister
        && (
          <Backlinks>
            Don&apos;t have an account?
            {' '}
            <Link
              as={Tag.BUTTON}
              title="Sign up now"
              onClick={(): void => {
                navigate(getLinkByName('register'));
              }}
            >
              Sign up now
            </Link>
          </Backlinks>
        )
      }
    </>
  );
}

import styled from 'styled-components';

import {
  H1 as KitH1,
  H4 as KitH4,
} from '@pushwoosh/kit-typography';

export const H1 = styled(KitH1)`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.2;

  color: #4B5057;
`;

export const H4 = styled(KitH4)`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
`;

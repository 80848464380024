import React, {
  useCallback,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAPI, PostApiReponsePayload, SAMLApiResponsePayload } from '~/src/hooks/useAPI';
import { trackMixpanelEvent } from '~/src/helpers/mixpanel';
import { sendToGoogleTagManager } from '~/src/helpers/sendToGoogleTagManager';

import { LoginPage } from './LoginPage.view';

import type { LoginSubmitData, ChekSSOData } from './LoginPage.types';

export function LoginPageWithSSO(): JSX.Element {
  const { callAPI } = useAPI();
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [isSSOChecked, setIsSSOChecked] = useState(false);

  const loginHandler = useCallback(({
    password,
    username,
    token,
  }: LoginSubmitData): Promise<void> => {
    const inviteCode = searchParams.get('invite');

    setErrorMessage('');

    return callAPI(
      'api/login',
      {
        password,
        username,
        'g-recaptcha-response': token,
        ...(inviteCode && { invite: inviteCode }),
      },
    )
      .then((response) => {
        const { redirect, message } = response as PostApiReponsePayload;

        sendToGoogleTagManager({ event: 'login' });
        trackMixpanelEvent({ name: 'AutorizationCompleted' });

        // awaiting redirect and ok response will be errored as unexpected behavior
        return redirect ? redirect() : setErrorMessage(message);
      })
      .catch((error) => setErrorMessage(error.message));
  }, [callAPI, searchParams]);

  const checkForSSOHandler = useCallback(({
    username,
    token,
  }: ChekSSOData): Promise<void> => {
    setErrorMessage('');

    return callAPI(
      'api/get-saml-endpoint',
      {
        username,
        'g-recaptcha-response': token,
      },
      'GET',
    )
      .then((response) => {
        const { ssoUrl } = response as SAMLApiResponsePayload;

        ssoUrl ? document.location.href = ssoUrl : setIsSSOChecked(true);
      })
      .catch((error) => setErrorMessage(error));
  }, [callAPI]);

  return (
    <LoginPage
      loginHandler={loginHandler}
      checkForSSOHandler={checkForSSOHandler}
      isSSOChecked={isSSOChecked}
      errorMessage={errorMessage}
    />
  );
}

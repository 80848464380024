import styled from 'styled-components';

export const FormStyled = styled.form`
  > * + * {
    &:not(:empty) {
      margin-top: 24px;
    }
  }
`;

export const InputFieldsBox = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 16px;
  }
`;

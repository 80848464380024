import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Link } from '@pushwoosh/kit-link';
import { TextInputField } from '@pushwoosh/kit-text-input';
import { Tag } from '@pushwoosh/kit-constants';

import { useEnvironment } from '~/src/contexts/environment';
import { ErrorCard } from '~/src/components/ErrorCard';
import { Backlinks } from '~/src/components/Backlinks';
import { ContentBox } from '~/src/components/ContentBox';
import { ButtonLoader } from '~/src/components/ButtonLoader';
import { useCountdown } from '~/src/hooks/useCountdown';

import {
  H1,
  FieldBox,
  Button,
  Checkbox,
} from '~/src/ui-kit';

import { authOptionName } from '../helpers/authOptionName';

import {
  HeaderBox,
  FormStyled,
  InputFieldsBox,
  ButtonsBox,
  CheckboxFootnote,
  OptionsBox,
} from './TwoFactorCode.styles';
import type { TwoFactorCodeProps } from './TwoFactorCode.types';

export function TwoFactorCode({
  sendCodeHandler,
  confirmHandler,
  errorMessage,
  isTrustedOption,
  authMethod = '',
  authOptions = [],
}: TwoFactorCodeProps): JSX.Element {
  const [isResendSubmitting, setResendSubmitting] = useState(false);
  const { waitSeconds, setCountStartDate } = useCountdown(60);
  const { getLinkByName, productName } = useEnvironment();
  const navigate = useNavigate();

  const isAllowSupport = productName === 'Pushwoosh';
  const isMultiAuthOptions = authOptions?.length > 1;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      confirmationCode: '',
      isTrusted: false,
    },
  });

  const onSendCodeSubmit = useCallback(() => {
    setResendSubmitting(true);
    sendCodeHandler().then(() => {
      setCountStartDate(Date.now());
      setResendSubmitting(false);
    });
  }, [sendCodeHandler, setCountStartDate, setResendSubmitting]);

  return (
    <>
      <ContentBox>
        <FormStyled onSubmit={handleSubmit(confirmHandler)}>
          <HeaderBox>
            <H1>Two-Factor Authentication</H1>
            <p>
              To verify your identity, please enter the code we have sent to you via
              {' '}
              {authOptionName(authMethod)}
              .
            </p>
          </HeaderBox>
          <InputFieldsBox>
            <FieldBox
              title="Confirmation Code"
              isErrored={!!errors.confirmationCode}
              helpText={errors.confirmationCode && 'Please enter verification code'}
            >
              <TextInputField
                $isErrored={!!errors.confirmationCode}
                $withIcon={false}
                autoFocus
                {...register('confirmationCode', { required: true })}
              />
            </FieldBox>
            {
              isTrustedOption
              && (
                <Checkbox
                  name="is-trusted"
                  value={watch('isTrusted')}
                  isChecked={watch('isTrusted')}
                  onChange={(checkbox): void => setValue('isTrusted', checkbox.isChecked)}
                >
                  Trust this device
                  <br />
                  <CheckboxFootnote>we will not ask for two-factor code for 30 days</CheckboxFootnote>
                </Checkbox>
              )
            }
            {!!errorMessage && !isSubmitting && <ErrorCard message={errorMessage} />}
          </InputFieldsBox>
          <ButtonsBox>
            <Button
              type="submit"
              isLoading={isSubmitting}
              color="primary"
              width="100%"
            >
              Confirm
            </Button>
            {
              !waitSeconds
                ? (
                  <Button
                    type="button"
                    isLoading={isSubmitting || isResendSubmitting}
                    onClick={onSendCodeSubmit}
                    color="primary"
                    view="ghost"
                    width="100%"
                  >
                    Resend code
                  </Button>
                )
                : <ButtonLoader>{`Please wait ${waitSeconds} seconds to resend email...`}</ButtonLoader>
            }
          </ButtonsBox>
          {(isAllowSupport || isMultiAuthOptions) && (
            <OptionsBox>
              Can’t access your account?
              {' '}
              {isMultiAuthOptions
                ? (
                  <Link
                    as={Tag.BUTTON}
                    title="Other options"
                    onClick={(): void => navigate(getLinkByName('authOptions'))}
                  >
                    Other options
                  </Link>
                )
                : (
                  <Link
                    title="Contact support"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://help.pushwoosh.com/hc/en-us/requests/new"
                  >
                    Contact support
                  </Link>
                )}
            </OptionsBox>
          )}
        </FormStyled>
      </ContentBox>
      <Backlinks>
        <Link title="Log out" href={getLinkByName('login')}>Log out</Link>
      </Backlinks>
    </>
  );
}

import styled from 'styled-components';
import { Color } from '@pushwoosh/kit-constants';
import { Spinner } from '@pushwoosh/kit-spinner';

export const LoaderBox = styled.div`
  background: ${Color.FROZEN};
  border-radius: 4px;
  border: 1px solid ${Color.FORM};

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 6px;
  font-weight: 500;
  color: #969CA3;
`;

export const ButtonLoaderSpinner = styled(Spinner)`
  margin-right: 4px;
`;

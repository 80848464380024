import styled from 'styled-components';
import { Color } from '@pushwoosh/kit-constants';

import { RouterLink } from '~/src/ui-kit';

export const FormStyled = styled.form`
  > * + * {
    margin-top: 24px;
  }
`;

export const InputFieldsBox = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 16px;
  }
`;

export const SideOptionsBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LinkStyled = styled(RouterLink)`
  line-height: 24px;
`;

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 12px;
  }
`;

export const ButtonText = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const HorizontalLine = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    margin: 0;
    color: ${Color.LOCKED}
  }
  hr {
    width: 150px;
    border-width: 1px 0px 0px;
    border-color: ${Color.DIVIDER};
    border-style: solid;
  }
`;

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { TextInputField } from '@pushwoosh/kit-text-input';
import { Link } from '@pushwoosh/kit-link';

import { useEnvironment } from '~/src/contexts/environment';
import {
  H1,
  FieldBox,
  Button,
  RouterLink,
} from '~/src/ui-kit';
import { ButtonLoader, ButtonLoaderSpinner } from '~/src/components/ButtonLoader';
import { Backlinks } from '~/src/components/Backlinks';
import { ContentBox } from '~/src/components/ContentBox';
import { ErrorCard } from '~/src/components/ErrorCard';
import { InfoNote } from '~/src/components/InfoNote';
import { ReCAPTCHA, useRecaptcha } from '~/src/components/Recaptcha';
import { validateEmail } from '~/src/helpers/validation';
import { useSearchParams } from 'react-router-dom';
import { FormStyled, InputFieldsBox } from './Register.styles';

import type { RegisterPageProps } from './Register.types';

export function RegisterPage({
  inviteEmail,
  isLoadedCountry,
  isLoadedInviteEmail,
  errorMessage,
  registerHandler,
}: RegisterPageProps): JSX.Element {
  const { getLinkByName, productName } = useEnvironment();
  const { getRecaptchaToken } = useRecaptcha(productName);
  const [searchParams] = useSearchParams();

  const showGoogle = searchParams.get('google') != null;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
  });

  useEffect(() => {
    setValue('email', inviteEmail);
  }, [inviteEmail, setValue]);

  async function onSubmit(): Promise<void> {
    await registerHandler({
      token: await getRecaptchaToken(),
      email: getValues('email'),
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
      phone: getValues('phone'),
    });
  }

  return (
    <>
      <ContentBox>
        {
          isLoadedCountry && showGoogle && (
            <Button as="a" href="https://user-api.svc-nue.pushwoosh.com/register-with-google" type="button" color="primary" width="100%" view="ghost">
              Sign Up With Google
            </Button>
          )
        }
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <H1>Create Account</H1>
          <InputFieldsBox>
            <FieldBox
              title="Business email *"
              isErrored={!!errors.email}
              helpText={errors.email && 'Please enter valid business email'}
            >
              <TextInputField
                $isErrored={!!errors.email}
                $withIcon={false}
                autoFocus
                disabled={!isLoadedInviteEmail}
                placeholder={!isLoadedInviteEmail ? 'Loading...' : ''}
                {...register('email', { required: true, validate: validateEmail })}
              />
            </FieldBox>
            <FieldBox
              title="First name *"
              isErrored={!!errors.firstName}
              helpText={errors.firstName && 'Please enter first name'}
            >
              <TextInputField
                $isErrored={!!errors.firstName}
                $withIcon={false}
                {...register('firstName', { required: true })}
              />
            </FieldBox>
            <FieldBox
              title="Last name *"
              isErrored={!!errors.lastName}
              helpText={errors.lastName && 'Please enter last name'}
            >
              <TextInputField
                $isErrored={!!errors.lastName}
                $withIcon={false}
                {...register('lastName', { required: true })}
              />
            </FieldBox>
            <FieldBox
              title="Phone number"
              isErrored={!!errors.phone}
              helpText={errors.phone && 'Please enter valid phone number'}
            >
              <TextInputField
                $isErrored={!!errors.phone}
                $withIcon={false}
                {...register('phone')}
              />
            </FieldBox>
            <ReCAPTCHA />
            {!!errorMessage && !isSubmitting && <ErrorCard message={errorMessage} />}
          </InputFieldsBox>
          {
            isLoadedCountry
              ? (
                <Button
                  width="100%"
                  type="submit"
                  color="primary"
                  isLoading={isSubmitting}
                >
                  Sign up
                </Button>
              )
              : (
                <ButtonLoader>
                  <ButtonLoaderSpinner size="small" />
                  Getting everything ready...
                </ButtonLoader>
              )
          }
          <InfoNote align="center">
            By signing up, you agree to the
            {' '}
            <Link
              target="_blank"
              title="Terms & Conditions"
              rel="noopener noreferrer"
              href="https://www.pushwoosh.com/privacy-policy/"
            >
              Privacy
            </Link>
            {' & '}
            <Link
              target="_blank"
              title="Terms & Conditions"
              rel="noopener noreferrer"
              href="https://www.pushwoosh.com/terms-of-use/"
            >
              Terms
            </Link>
          </InfoNote>
        </FormStyled>
      </ContentBox>
      <Backlinks>
        Already have an account?
        {' '}
        <RouterLink title="Sign in" to={getLinkByName('login')}>Sign in</RouterLink>
      </Backlinks>
    </>
  );
}

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { TextInputField } from '@pushwoosh/kit-text-input';
import { Link } from '@pushwoosh/kit-link';

import { useEnvironment } from '~/src/contexts/environment';
import {
  H1,
  FieldBox,
  Button,
  RouterLink,
} from '~/src/ui-kit';
import { ButtonLoader, ButtonLoaderSpinner } from '~/src/components/ButtonLoader';
import { Backlinks } from '~/src/components/Backlinks';
import { ContentBox } from '~/src/components/ContentBox';
import { ErrorCard } from '~/src/components/ErrorCard';
import { InfoNote } from '~/src/components/InfoNote';
import { ReCAPTCHA, useRecaptcha } from '~/src/components/Recaptcha';
import { validateEmail } from '~/src/helpers/validation';
import { GoogleLogoSvg } from '../LoginPage/GoogleLogoSvg';

import type { RegisterPageProps } from './Register.types';

import {
  TitleBox,
  ButtonsBox,
  ButtonText,
  FormStyled,
  HorizontalLine,
  InputFieldsBox,
} from './Register.styles';

export function RegisterPage({
  inviteCode,
  inviteEmail,
  isLoadedCountry,
  isLoadedInviteEmail,
  errorMessage,
  registerHandler,
}: RegisterPageProps): JSX.Element {
  const { getLinkByName, productName } = useEnvironment();
  const { getRecaptchaToken } = useRecaptcha(productName);

  const isAllowGoogleSignUp = productName === 'Pushwoosh';

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
  });

  useEffect(() => {
    setValue('email', inviteEmail);
  }, [inviteEmail, setValue]);

  async function onSubmit(): Promise<void> {
    await registerHandler({
      token: await getRecaptchaToken(),
      email: getValues('email'),
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
      phone: getValues('phone'),
    });
  }

  return (
    <>
      <ContentBox>
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <TitleBox>
            <H1>Start your 30-day free trial</H1>
            <span>
              All features available with a&nbsp;1000-subscriber limit, ideal for testing and integration.
            </span>
          </TitleBox>
          <InputFieldsBox>
            <FieldBox
              title="Business email *"
              isErrored={!!errors.email}
              helpText={errors.email && 'Please enter valid business email'}
            >
              <TextInputField
                $isErrored={!!errors.email}
                $withIcon={false}
                autoFocus
                disabled={!isLoadedInviteEmail || inviteEmail !== ''}
                placeholder={!isLoadedInviteEmail ? 'Loading...' : ''}
                {...register('email', { required: true, validate: validateEmail })}
              />
            </FieldBox>
            <FieldBox
              title="First name *"
              isErrored={!!errors.firstName}
              helpText={errors.firstName && 'Please enter first name'}
            >
              <TextInputField
                $isErrored={!!errors.firstName}
                $withIcon={false}
                {...register('firstName', { required: true })}
              />
            </FieldBox>
            <FieldBox
              title="Last name *"
              isErrored={!!errors.lastName}
              helpText={errors.lastName && 'Please enter last name'}
            >
              <TextInputField
                $isErrored={!!errors.lastName}
                $withIcon={false}
                {...register('lastName', { required: true })}
              />
            </FieldBox>
            <FieldBox
              title="Phone number"
              isErrored={!!errors.phone}
              helpText={errors.phone && 'Please enter valid phone number'}
            >
              <TextInputField
                $isErrored={!!errors.phone}
                $withIcon={false}
                {...register('phone')}
              />
            </FieldBox>
            <ReCAPTCHA />
            {!!errorMessage && !isSubmitting && <ErrorCard message={errorMessage} />}
          </InputFieldsBox>
          <ButtonsBox>
            {
              isLoadedCountry
                ? (
                  <>
                    <Button
                      width="100%"
                      type="submit"
                      color="primary"
                      isLoading={isSubmitting}
                    >
                      Sign up
                    </Button>
                    {
                      isAllowGoogleSignUp
                      && (
                        <>
                          <HorizontalLine>
                            <hr />
                            <p>OR</p>
                            <hr />
                          </HorizontalLine>
                          {
                          isSubmitting
                            ? (
                              <ButtonLoader>
                                <ButtonText>
                                  <GoogleLogoSvg opacity="50%" />
                                  Sign Up With Google
                                </ButtonText>
                              </ButtonLoader>
                            )
                            : (
                              <Button
                                as="a"
                                href={inviteCode === '' ? 'https://user-api.svc-nue.pushwoosh.com/register-with-google' : `https://user-api.svc-nue.pushwoosh.com/register-with-google?invite=${inviteCode}`}
                                type="button"
                                color="secondary"
                                width="100%"
                              >
                                <ButtonText>
                                  <GoogleLogoSvg />
                                  Sign Up With Google
                                </ButtonText>
                              </Button>
                            )
                        }
                        </>
                      )
                    }
                  </>
                )
                : (
                  <ButtonLoader>
                    <ButtonLoaderSpinner size="small" />
                    Getting everything ready...
                  </ButtonLoader>
                )
              }
          </ButtonsBox>
          <InfoNote align="center">
            By signing up, you agree to the
            {' '}
            <Link
              target="_blank"
              title="Terms & Conditions"
              rel="noopener noreferrer"
              href="https://www.pushwoosh.com/privacy-policy/"
            >
              Privacy
            </Link>
            {' & '}
            <Link
              target="_blank"
              title="Terms & Conditions"
              rel="noopener noreferrer"
              href="https://www.pushwoosh.com/terms-of-use/"
            >
              Terms
            </Link>
          </InfoNote>
        </FormStyled>
      </ContentBox>
      <Backlinks>
        Already have an account?
        {' '}
        <RouterLink title="Sign in" to={getLinkByName('login')}>Sign in</RouterLink>
      </Backlinks>
    </>
  );
}

import styled from 'styled-components';

import { Color, Spacing } from '@pushwoosh/kit-constants';

export const TitleBox = styled.div`
  color: ${Color.MAIN};
  gap: ${Spacing.S1};
`;

export const FormStyled = styled.form`
  > * + * {
    &:not(:empty) {
      margin-top: 24px;
    }
  }
`;

export const InputFieldsBox = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 16px;
  }
`;

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 12px;
  }
`;

export const ButtonText = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const HorizontalLine = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    margin: 0;
    color: ${Color.LOCKED}
  }

  hr {
    width: 150px;
    border-width: 1px 0px 0px;
    border-color: ${Color.DIVIDER};
    border-style: solid;
  }
`;

import React from 'react';

export function GoogleLogoSvg({ opacity = '100%' }): JSX.Element {
  return (
    <svg opacity={opacity} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.64 12.2042C20.64 11.566 20.5827 10.9524 20.4764 10.3633H12V13.8487H16.8436C16.6309 14.9696 15.9927 15.9187 15.0355 16.5569V18.8233H17.9564C19.6582 17.2524 20.64 14.9451 20.64 12.2042Z" fill="#4285F4" />
      <path d="M11.9996 20.9986C14.4296 20.9986 16.4669 20.1968 17.956 18.8223L15.0351 16.5559C14.2333 17.0959 13.2105 17.4232 11.9996 17.4232C9.65963 17.4232 7.67145 15.8441 6.95963 13.7168H3.96509V16.0404C5.446 18.9777 8.48145 20.9986 11.9996 20.9986Z" fill="#34A853" />
      <path d="M6.96 13.7095C6.78 13.1695 6.67364 12.5967 6.67364 11.9995C6.67364 11.4022 6.78 10.8295 6.96 10.2895V7.96582H3.96545C3.35182 9.17673 3 10.5431 3 11.9995C3 13.4558 3.35182 14.8222 3.96545 16.0331L6.29727 14.2167L6.96 13.7095Z" fill="#FBBC05" />
      <path d="M11.9996 6.58364C13.3251 6.58364 14.5033 7.04182 15.4442 7.92545L18.0215 5.34818C16.4587 3.89182 14.4296 3 11.9996 3C8.48145 3 5.446 5.02091 3.96509 7.96636L6.95963 10.29C7.67145 8.16273 9.65963 6.58364 11.9996 6.58364Z" fill="#EA4335" />
    </svg>
  );
}

import styled from 'styled-components';

import { Spinner } from '@pushwoosh/kit-spinner';

export const LoaderBox = styled.div`
  background: rgba(136, 158, 184, 0.1);
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-weight: 500;
  color: #969CA3;
`;

export const ButtonLoaderSpinner = styled(Spinner)`
  margin-right: 4px;
`;
